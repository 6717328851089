<template>
    <main class="swcluster-main" id="swcluster-datasandbox">
        <!-- page-header -->
        <div class="page-header header-divider">
            <div class="header-component">
                <CommonBreadcrumb :show-title="true" />
            </div>
        </div>

        <!-- page-body -->
        <div class="page-body page-component">
            <!-- page-aside -->
            <aside class="page-aside">
                <nav class="snb">
                    <ol class="snb-list">
                        <li
                            v-for="(board, index) in boardTypes"
                            class="snb-item"
                            :class="{'is-active': board.dcd === filters.boardTypeDcd}"
                            @click="selectBoardType(board.dcd)"
                            :key="index">
                            <a href="javascript:" class="snb-link">
                                {{ board.name }}
                                <span class="sub">{{ getBoardTypeCount(board.dcd) }}</span>
                            </a>
                        </li>
                    </ol>
                </nav>
            </aside>

            <!-- page-contents -->
            <div class="page-contents">
                <nav v-if="filters.boardTypeDcd === domainCode.BOARD_TYPE_BUSINESS" class="page-tabs">
                    <ul class="tab-list">
                        <li
                            v-for="(board, index) in boardGbs"
                            class="tab-item"
                            :class="{'is-active': board.dcd === conditionalFilters.boardGbDcd}"
                            @click="selectBoardGb(board.dcd)"
                            :key="index">
                            <a href="javascript:" class="tab">{{ board.name }}</a>
                        </li>
                    </ul>
                </nav>

                <!-- board-list-container -->
                <div class="board-list-container">
                    <!-- board-list -->
                    <ul class="board-list">
                        <!-- board-item -->
                        <li v-for="(item, index) in items" class="board-item" :key="index">
                            <div class="board">
                                <div class="board-column column-content">
                                    <router-link :to="{name: 'NoticeView', params: {bno: item.bno}}" class="board-link">
                                        <p class="board-title">{{ item.boardNm }}</p>
                                        <div class="board-meta">
                                            <span class="text">{{ getDcdName(item.boardTypeDcd) }}</span>
                                            <span class="text">{{ timestampToDateFormat(item.createDt, 'yyyy.MM.dd') }}</span>

                                            <div v-if="filters.boardTypeDcd === domainCode.BOARD_TYPE_BUSINESS && item.boardGbDcd" class="badge">
                                                {{ getDcdName(item.boardGbDcd) }}
                                            </div>
                                        </div>
                                    </router-link>
                                </div>
                            </div>
                        </li>
                        <!-- board-item -->
                    </ul>
                    <!-- pagination -->
                    <CommonPagination :paging="paging" :page-func="pageFunc" />
                </div>
                <!-- board-list-container -->
            </div>
            <!-- //page-contents -->
        </div>
        <!-- //page-body -->
        <div v-if="session.name !== null && session.manager && showButton" class="page-bottom bottom-fixed" style="bottom: 16px">
            <div class="bottom-board-upload bottom-component">
                <button class="btn btn-primary" @click="goEdit"><span class="text">글작성</span></button>
            </div>
        </div>
    </main>
</template>

<script>
import {computed, reactive, ref, watch} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {getValueByQuery} from '@/assets/js/route.utils';
import CommonPagination from '@/components/common/CommonPagination';
import {getCheckItems, getItems, getTotalCount, lengthCheck, timestampToDateFormat} from '@/assets/js/common.utils';
import {getBoardList} from '@/assets/js/modules/board/module';
import CommonBreadcrumb from '@/components/common/layout/CommonBreadcrumb';
import {useStore} from 'vuex';
import {domainCode, getDcdName} from '@/assets/js/domain.code';
import ApiService from '@/assets/js/api.service';

export default {
    name: 'Notice',
    components: {CommonBreadcrumb, CommonPagination},
    setup() {
        const store = useStore();
        const router = useRouter();
        const route = useRoute();
        const session = computed(() => store.state.auth.session);
        const items = ref([]);
        const counts = ref([]);
        const paging = reactive({
            pageNo: getValueByQuery(route.query, 'pageNo', true, 1),
            pageSize: 10,
            totalCount: 0,
        });

        const filters = reactive({
            boardTypeDcd: getValueByQuery(route.query, 'boardTypeDcd', false, null),
        });

        const conditionalFilters = reactive({
            boardGbDcd: getValueByQuery(route.query, 'boardGbDcd', false, null),
        });

        const boardTypes = [
            {name: '전체', dcd: null},
            {name: '공지', dcd: domainCode.BOARD_TYPE_NOTICE},
            {name: '사업공지', dcd: domainCode.BOARD_TYPE_BUSINESS},
            {name: '자료실', dcd: domainCode.BOARD_TYPE_REFERENCE},
            {name: '보도자료', dcd: domainCode.BOARD_TYPE_REPORT},
        ];

        const boardGbs = [
            {name: '전체', dcd: null},
            {name: '대구', dcd: domainCode.BOARD_GB_DAEGU},
            {name: '제주', dcd: domainCode.BOARD_GB_JEJU},
        ];

        const selectBoardType = dcd => {
            router.push({query: {...filters, boardTypeDcd: dcd}});
        };

        const selectBoardGb = dcd => {
            router.push({query: {...filters, boardGbDcd: dcd}});
        };

        const pageFunc = pageNo => {
            router.push({query: {pageNo: pageNo}});
        };

        const getBoardTypeCount = boardTypeDcd => {
            if (boardTypeDcd) {
                const target = counts.value.find(x => x.boardTypeDcd === boardTypeDcd);
                return target ? target.boardCount : 0;
            }

            if (counts.value.length > 0) {
                return counts.value.map(x => x.boardCount).reduce((a, b) => a + b, 0);
            }
            return 0;
        };

        const getBoardNoticeList = () => {
            const params = {...filters, ...paging, noticeYn: 'Y'};
            if (params.boardTypeDcd === domainCode.BOARD_TYPE_BUSINESS) {
                Object.keys(conditionalFilters).forEach(key => {
                    params[key] = conditionalFilters[key];
                });
            }

            getBoardList(params).then(res => {
                if (lengthCheck(res)) {
                    items.value = getItems(res);
                    paging.totalCount = getTotalCount(res);
                }
            });

            ApiService.query('/v1/boards/notices/counts', {}).then(res => {
                counts.value = getCheckItems(res);
            });
        };

        // notice => 공지사항
        watch(
            () => route.query,
            () => {
                if (route.name === 'Notice') {
                    paging.pageNo = getValueByQuery(route.query, 'pageNo', true, 1);
                    filters.boardTypeDcd = getValueByQuery(route.query, 'boardTypeDcd', false, null);
                    conditionalFilters.boardGbDcd = getValueByQuery(route.query, 'boardGbDcd', false, null);

                    items.value = [];
                    paging.totalCount = 0;

                    // 실행 api 실행 됨
                    getBoardNoticeList();
                }
            },
        );

        const showButton = ref(true);
        // const toggleButtonHandler = () => {
        //     const scrollTop = window.scrollY;
        //     const innerHeight = window.innerHeight;
        //     const scrollHeight = document.body.scrollHeight;
        //     const isShow = !(scrollTop + innerHeight >= scrollHeight);
        //     if (showButton.value !== isShow) showButton.value = isShow;
        // };
        //
        // onMounted(() => {
        //     document.addEventListener('scroll', toggleButtonHandler);
        // });
        //
        // onUnmounted(() => {
        //     document.removeEventListener('scroll', toggleButtonHandler);
        // });

        const goEdit = e => {
            if (e.ctrlKey) {
                window.open('/board/notice/0');
            } else {
                router.push({name: 'NoticeEdit', params: {bno: 0}});
            }
        };

        getBoardNoticeList();

        return {
            session,
            filters,
            conditionalFilters,
            boardTypes,
            boardGbs,
            items,
            paging,
            showButton,
            getBoardTypeCount,
            selectBoardType,
            selectBoardGb,
            getDcdName,
            pageFunc,
            goEdit,
            timestampToDateFormat,

            domainCode,
        };
    },
};
</script>
